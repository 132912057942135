.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 11%);
  z-index: 1000;
}

.spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.overflow-ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 23vw;
}

@media(max-width: 479px) {
  .overflow-ellipse {
    max-width: 90vw;
  }
}